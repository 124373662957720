import Link from 'next/link'
import React, { memo, useMemo, useState } from 'react'

import { ContentCard } from '@/components/elements/cards/contentCard'
import { IndividualsAvatarGroup } from '@/components/elements/images/individualsAvatarGroup'
import VideoThumbnail from '@/components/elements/video/videoThumbnail'
import { ConditionalWrapper } from '@/components/hoc/conditionalWrapper'
import { dateFormat } from 'utils/dates'

export const seminarMocked = {
  attributes: {
    title: '',
    description: '',
    publish_date: '',
    categories: [],
    technologies: [],
    individuals: [],
    image: '',
    link: '',
    video: '',
    cover: null,
    subtitles: []
  }
}

const _SeminarCard = ({ seminar, clamp, isShimmer, isGalleryCard }) => {
  const { id, attributes } = seminar
  const { title, description, individuals, cover, video, publish_date, subtitles } = attributes
  const [startTime, setStartTime] = useState(0)
  const date = new Date(publish_date)
  const href = id ? `/seminars/${id}` : undefined

  const formattedCover = cover?.data[0].attributes ?? ''
  const changeTime = time => setStartTime(time)

  const individualAvatars = useMemo(
    () => (
      <IndividualsAvatarGroup individuals={individuals.data} maxPopoverTrigger="hover" />
    ),
    [individuals]
  )

  return (
    <ConditionalWrapper
      condition={href}
      wrapper={children => <Link href={`${href}?t=${startTime}`}>{children}</Link>}
    >
      <div className="seminar-container">
        <ContentCard
          cover={
            <VideoThumbnail
              video={video}
              cover={formattedCover}
              playOnHover
              updateCurrentTime={changeTime}
              subtitles={subtitles?.data}
            />
          }
          video={video}
          title={title}
          description={description}
          date={dateFormat.Month_D_Yr(date)}
          clamp={clamp}
          isShimmer={isShimmer || !title}
          isGalleryCard={isGalleryCard}
          bottom={individualAvatars}
        />
      </div>
    </ConditionalWrapper>
  )
}

export const SeminarCard = memo(_SeminarCard)
