import _ from 'lodash'

export const concatArray = array => {
  if (array.length === 0) return ''
  if (array.length === 1) return array[0]
  return `${array.slice(0, -1).join(', ')} and ${array.slice(-1)}`
}

const createSafeJoinBySeparator = separator =>
  _.flow([_.compact, _.partial(_.join, _, separator)])

export const safeJoinBySpace = createSafeJoinBySeparator(' ')
