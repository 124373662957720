import { createContext, createRef, useContext } from "react"

const playerRef = createRef()
const PlayerContext = createContext()

export const PlayerContextProvider = (props) => {
  return (
    <PlayerContext.Provider value={playerRef}>
      {props.children}
    </PlayerContext.Provider>
  )
}

export const usePlayerContext = () => useContext(PlayerContext)
