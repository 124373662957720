import { isEmpty } from 'lodash'
import { usePlayerContext } from 'common/contexts/playerContext'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

const makeConfig = (subtitles) => (!isEmpty(subtitles) ? { file: { attributes : { crossOrigin: 'true' }, tracks: subtitles } } : undefined)

const _VideoPlayer = ({ startTime = 0, updateCurrentTime, subtitles, ...props }) => {
  const playerRef = usePlayerContext() ?? useRef(null)
  const [isReady, setIsReady] = useState(false)
  const [hasWindow, setHasWindow] = useState(false);

  // HACK: wait until window (exclusive from client side) exists
  // to avoid hydration error. Cannot use dynamic import due to ref usage.
  // More info in https://github.com/cookpete/react-player/issues/1428#issuecomment-1085454151
  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);

  const config = useMemo(() => makeConfig(subtitles), [subtitles])

  useEffect(() => {
    setIsReady(false)
  }, [startTime])

  const onReady = useCallback(() => {
    if (!isReady && playerRef.current) {
      playerRef.current.seekTo(startTime, 'seconds')
      setIsReady(true)
    }
  }, [isReady, playerRef, startTime])

  const onMouseLeave = () => {
    if (updateCurrentTime && playerRef.current) {
      updateCurrentTime(playerRef.current.getCurrentTime())
    }
  }

  if (!hasWindow) return <></>

  return (
    <div className="player-container" onMouseLeave={onMouseLeave}>
      <ReactPlayer {...props} ref={playerRef} onReady={onReady} config={config} />
    </div>
  )
}

export const VideoPlayer = memo(_VideoPlayer)
