import { sortBy } from 'lodash'
import React from 'react'

import { CustomAvatarGroup } from './avatarGroup'
import { IndividualAvatar } from './individual'

export const IndividualsAvatarGroup = ({
  individuals = [],
  size = 'normal',
  fontSize,
  max = 5,
  className,
  ...props
}) => {
  const renderAvatar = individual => (
    <IndividualAvatar key={individual.id} individual={individual} />
  )
  const sortedItem = sortBy(individuals, item => item?.attributes?.avatar?.data)

  return (
    <CustomAvatarGroup
      items={sortedItem}
      renderAvatar={renderAvatar}
      maxCount={max}
      size={size}
      fontSize={fontSize}
      className={className}
      {...props}
    />
  )
}
