import { memo, useCallback } from 'react'

import Scroller from '@/components/elements/gallery/scroller'
import { SeminarCard } from '@/components/sections/seminars/seminarCard'

const SeminarGallery = ({
  seminars,
  arrows = true,
  gradient = true,
  hideScrollbarDesktop = true
}) => {
  const renderSeminar = useCallback(
    (item, index) => (
      <SeminarCard
        key={`seminar-${index}`}
        seminar={item}
        tagsScroll={false}
        clamp
        isGalleryCard
      />
    ),
    []
  )

  return (
    <Scroller
      array={seminars}
      render={renderSeminar}
      arrows={arrows}
      gradient={gradient}
      hideScrollbarDesktop={hideScrollbarDesktop}
    />
  )
}

export default memo(SeminarGallery)
