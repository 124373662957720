import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { memo, useState } from 'react'

import CustomImage from '@/components/elements/images/image'
import { VideoPlayer } from '@/components/elements/video/videoPlayer'
import { createGetSeminarsVideoEndpoint } from 'utils/endpoints'
import { mapRawSubtitles } from 'utils/subtitles'

const VideoThumbnail = ({
  video,
  cover,
  playOnHover = false,
  muted = true,
  controls = true,
  className,
  subtitles,
  ...props
}) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const videoUrl = video && createGetSeminarsVideoEndpoint(video)
  const mappedSubtitles = subtitles?.map(mapRawSubtitles) ?? []
  const classes = classNames('video-thumbnail-container', className)

  const onMouseEnter = () => playOnHover && !isEmpty(video) && setIsPlaying(true)
  const onMouseLeave = () => {
    playOnHover && setIsPlaying(false)
  }

  return (
    <div className={classes} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {!isPlaying && (
        <CustomImage
          media={cover}
          layout="fill"
          objectPosition="center"
          objectFit="cover"
          priority
        />
      )}
      {!isEmpty(video) && (
        <VideoPlayer
          url={videoUrl}
          playing={isPlaying}
          muted={muted}
          width="100%"
          height="100%"
          controls={controls}
          {...props}
          subtitles={mappedSubtitles}
        />
      )}
    </div>
  )
}

export default memo(VideoThumbnail)
