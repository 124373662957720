import React from 'react'
import { generateRandomLightColor } from 'utils/randomColor'
import { safeJoinBySpace } from 'utils/strings'

import { tertiaryBackgroundColor } from '../../../stylesheet/abstracts/variables.module.scss'
import { CustomAvatar } from './avatar'

export const IndividualAvatar = ({
  individual: {
    id,
    attributes: { name, surname, avatar }
  },
  ...props
}) => {
  const fullname = safeJoinBySpace([name, surname])
  return (
    <CustomAvatar
      key={id}
      name={fullname}
      tooltip={fullname}
      src={avatar?.data?.attributes?.url}
      color={generateRandomLightColor(fullname)}
      style={{ borderColor: tertiaryBackgroundColor }}
      {...props}
    />
  )
}
