import { useMockUpFetch } from 'common/hooks'
import isEmpty from 'lodash/isEmpty'
import { memo } from 'react'
import { createGetDataEndpoint } from 'utils/endpoints'

import Card from '@/components/elements/card'
import SeminarGallery from '@/components/elements/gallery/seminarGallery'
import HeaderOverhaul from '@/components/elements/headerOverhaul'
import { seminarMocked } from '@/components/sections/seminars/seminarCard'
import Link from 'next/link'
import { Button } from '@/components/elements/buttons/button'

const NO_SEMINARS = "Couldn't find any seminar at the moment"

const LatestSeminars = ({ data: { header, numberOfSeminars } }) => {
  const { state: seminars } = useMockUpFetch({
    limit: numberOfSeminars,
    mocked: seminarMocked,
    endpoint: createGetDataEndpoint({
      endpoint: 'seminars',
      limit: numberOfSeminars,
      sortBy: 'publish_date'
    })
  })

  return (
    <div className="latest-seminars-wrapper">
      <HeaderOverhaul header={header} subtitle />
      <Link href="/seminars">
        <Button text="See more" className="latest-projects-button" />
      </Link>
      {!isEmpty(seminars) ? (
        <div className="latest-seminars-container">
          <SeminarGallery seminars={seminars} />
        </div>
      ) : (
        <Card placeholder={NO_SEMINARS} />
      )}
    </div>
  )
}

export default memo(LatestSeminars)
