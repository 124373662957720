import { memo } from 'react'

import ScrollDrag from '@/components/hoc/dragScroll'

const Scroller = ({
  array,
  render,
  arrows = true,
  gradient = true,
  hideScrollbarDesktop = true
}) => (
  <ScrollDrag
    arrows={arrows}
    gradient={gradient}
    hideScrollbarDesktop={hideScrollbarDesktop}
  >
    {array.map((item, index) => render(item, index))}
  </ScrollDrag>
)

export default memo(Scroller)
