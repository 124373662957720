import { createGetSeminarsTranscriptionsEndpoint } from "./endpoints";
import { getLanguageFromCode } from "./lang"

export function mapRawSubtitles({ attributes }) {
  const { file, lang: langCode } = attributes;
  const langValue = getLanguageFromCode(langCode.split('-')[0]);

  return {
    kind: 'subtitles',
    src: createGetSeminarsTranscriptionsEndpoint(file),
    srcLang: langCode,
    default: langCode === "en-US",
    label: langValue
  };
};
